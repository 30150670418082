import React from "react";
import { Link } from "gatsby";
import "./header.css";
// import {Button} from '@material-ui/core/Button';

export default function Header(props) {
  return (
    <header>
      <div>
        <Link className="link first" to="/">
          <p>HOME</p>
        </Link>
      </div>

      {/* <Button variant="outlined" color="primary" href="#outlined-buttons">
        Link
      </Button> */}

      <Link className="link" to="/about/">
        <p>ABOUT</p>
      </Link>

      <Link className="link" to="/projects/">
        <p>PROJECTS</p>
      </Link>
    </header>
  );
}
